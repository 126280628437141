import './App.css';
import logo from './assets/img/logo.png';
import Home from './pages/Home';

function App() {
  return (
    <div className="App">
      <header className='fixed w-screen border-box px-[5vw] py-[1rem] border-solid border-black bg-[#F6F1F1] flex flex-row gap-2 z-10'>
        <img className="w-10" src={logo} alt='Logo'/> 
        <h2 className='flex flex-row text-[1.5rem] max-sm:text-sm items-center text-black'>ImageCompressor</h2>
      </header>
      <Home/>
      <footer className='w-full min-h-[10rem] bg-[black] flex items-end justify-center p-5'>
          <small className=''>© 2023 by Code Crafted. All rights reserved.</small>
      </footer>
    </div>
  );
}

export default App;
