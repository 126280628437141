import React from 'react'
import Button from '../Button/Button'

export default function Alert({onClick = {}, label = "Ok", wrapperClass = "bg-[red]", title, message} 
                                : {onClick?:any, label?:any, wrapperClass?:any | "", title:any, message:any}) {
  return (
    <section className='w-screen h-screen fixed flex justify-center items-center text-black bg-[#4c4c4ca8] left-0 top-0 z-10 p-5'>
        <article className='w-1/3 h-1/4 bg-[white] max-[640px]:w-full rounded-[5px] p-5 flex flex-col items-center justify-center gap-1'>
            <label className='text-2xl'>{title}</label>
            <p className='text-xl'>{message}</p>
            <Button onClick={onClick} label={label}  wrapperClass={wrapperClass}/>
        </article>
    </section>
  )
}
