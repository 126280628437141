import { useRef, useState } from "react";
import ImagePreview from "../Preview/ImagePreview";
import upload from '../../assets/img/upload.png';

import './DragInput.scss';
import Loader from "../Loader/Loader";
import Alert from "../Alert/Alert";
import Button from "../Button/Button";
import { CompressFile } from "../Helper";
import { Box, Checkbox, Input, Slider, Switch, Typography } from "@mui/material";

export default function DragInput({setInputFiles} : {setInputFiles:any}) {

    const [isDragActive, setIsDragActive] = useState<boolean>(false);
    const inputRef = useRef<any>(null);
    const [files, setFiles] = useState<any>([]);
    const [compressedFiles, setCompressedFiles] = useState<any>([]);
    const [warningUpload, setWarningUpload] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const [isCompressing, setCompressing] = useState(false);
    const [openAdvance, setAdvance] = useState(false);

    const [maxSizeMB, setMaxSize] = useState(Number.POSITIVE_INFINITY);
    const [maxWidthHeight, setMaxWidthHeight] = useState<number>(0);
    const [quality, setQuality] = useState(100);
    const [keepResolution, setKeepResolution] = useState(false);

    const options = {
      maxSizeMB : maxSizeMB,
      maxWidthOrHeight : maxWidthHeight === 0 ? undefined : maxWidthHeight,
      initialQuality : quality/100,
      alwaysKeepResolution: keepResolution
    }


    function setQualityValue(value : any) {
      const q = Number(value);
      setQuality(q);
    }

    const handleSlideChange = (event: Event, newValue: number | number[]) => {
      if (typeof newValue === 'number') {
        setQuality(newValue);
      }
    };

    function deleteAllFiles() {
        setFiles([]);
    }

    function delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    async function fileUpload(data : any) {
        if(data.length > 5 || files.length === 5) {
          setWarningUpload(true);
        } else{
          setUploading(true);
          await delay(2000);
          if (data && data[0]) {
              for (let i = 0; i < data["length"]; i++) {
                setFiles((prevState: any) => [...prevState, data[i]]);
              }
            }
          setUploading(false);
      }
    }

    function closeWarning() {
      setWarningUpload(false);
    }

    function isEmpty() {
        return files.length === 0;
    }


    const handleSelect = (e : any) => {
        e.preventDefault();
        fileUpload(e.target.files);
    }

    function handleDrop(e: any) {
        e.preventDefault();
        e.stopPropagation();
        setIsDragActive(false);
        fileUpload(e.dataTransfer.files);
      }

      const handleDelete = (i : any) => {
        console.log("delete");
        setFiles(((prev:any) => [...prev.slice(0,i),...prev.slice(i+1)]))
      }


      function handleEnter(e: any) {
        e.preventDefault();
        e.stopPropagation();
        setIsDragActive(true);
      }

      function handleLeave(e: any) {
        e.preventDefault();
        e.stopPropagation();
        setIsDragActive(false);
      }
    
      function handleOver(e: any) {
        e.preventDefault();
        e.stopPropagation();
        setIsDragActive(true);
      }
    
      function openFileExplorer() {
        inputRef.current.value = "";
        inputRef.current.click();
      }

      const compressFile = async () => {
        setCompressing(true);
        setCompressedFiles([]);

        await delay(2000);

        Promise.all( 
          files.map(async (file: File) => {
            const comp = await CompressFile(file, options);
            compressedFiles.push(comp);
          })
        ).then((res) =>{
          console.log(res);
          setInputFiles(compressedFiles);
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
            setCompressing(false);
        });
  }
    

  return (
    <div className="drag-container w-full flex flex-col items-center">
      {
        warningUpload ? <Alert 
        title={"Warning"}
        message={"You can upload maximum 5 files at once."}
        onClick={closeWarning}
        wrapperClass={"bg-[#141E46] text-white"}
        /> : null
      }
      
      <form
        className={`${isDragActive ? "bg-[#a3a3a3]" : "bg-[#8fa8a5]"} hover:bg-[#a3a3a3] min-w-[70%] md:min-h-[20rem] lg:min-h-[20rem] max-[640px]:w-full max-[640px]:min-h-[20rem] max-md:w-3/4 max-lg:w-3/4 h-min-64 py-[1rem] 
                        box-border rounded-[5px] text-center flex flex-col items-center justify-center text-black duration-500 px-[1rem] gap-[.5rem]`}
        onDragEnter={handleEnter}
        onSubmit={ (e) => e.preventDefault()}
        onDrop={handleDrop}
        onDragLeave={handleLeave}
        onDragOver={handleOver}>
        
        <img id="upload-img" className="w-10 cursor-pointer hover:scale-110 duration-500" src={upload} onClick={openFileExplorer} alt="Upload icon"/>
        <label htmlFor="upload-img" className="font-light cursor-pointer" onClick={openFileExplorer}>Upload Images</label> 
        <input
            className=""
            type="file"
            id="input-file"
            accept="image/*"
            ref={inputRef}
            onChange={handleSelect}
            style={{ display: "none" }}
            multiple
        />

        <p className="font-bold m-[1rem] ">
            Or Drag & Drop files Here
        </p>

        {
          !isEmpty() ? <article className="flex flex-col pt-10 w-full ">
          <aside className=" flex flex-row flex-wrap p-1 items-end justify-center">
              {
                  files.map((file: any, i: any) => {
                      return <>
                              <ImagePreview onClick={() => handleDelete(i)} imageFile={file} mode={"input"} key={i}/>
                              </>
                  })
              }
          </aside>
          {/* Remove all button */}
          { files.length > 1 ?  <button className="text-white" onClick={deleteAllFiles}>Remove All</button> : null}
          </article>
          : null
        }
        

            {/* Compress button */}
      { !isEmpty() ? 
            <Button disabled={isCompressing} onClick={compressFile} label={"Compress"} wrapperClass={"bg-[#e54630] text-white"}/> : null }

      

      </form>
      
      <label className="text-xs mt-2">Supported Types: JPEG, JPG, PNG, GIF</label>
      
      <Box className="min-w-[70%] max-[640px]:w-full max-md:w-3/4 max-lg:w-3/4 flex flex-col justify-end items-start duration-500 text-[#F6F1F1]">
        <label>Advance Options <Switch aria-label="Switch" onClick={()=> setAdvance(!openAdvance)}/></label>
        <Box  className={`${openAdvance ? "h-[14rem]" : "h-[0] p-0 opacity-0"} w-full overflow-hidden flex flex-col justify-center 
                                  items-start gap-[.5rem] duration-1000 rounded-[5px] p-[1rem] bg-[#8fa8a5]`}>
            <span className="flex flex-row gap-[1rem]">
              <label>Max Size in MB: </label>
              <Input size="small" className="w-[3rem]" inputProps={{type:'number',placeholder:"MaxSize(MB)", min:0}} value={maxSizeMB} onChange={(e) => setMaxSize(Number(e.target.value))}/>
            </span>
            
            <span className="flex flex-row gap-[1rem]">
              <label>Max Height/Width in px: </label>
              <Input size="small" className="w-[3rem]" inputProps={{type:'number',placeholder:"Max Width or Height(px)", min:0}} value={maxWidthHeight} onChange={(e) => setMaxWidthHeight(Number(e.target.value))}/>
            </span>

            <Typography id="input-slider" gutterBottom>Quality:</Typography>
            <Box className="w-1/2 max-[640px]:w-full flex flex-row gap-[2rem] justify-center items-center">
              <Slider
                getAriaLabel={() => 'Minimum distance shift'}
                valueLabelDisplay="auto"
                value={quality}
                onChange={handleSlideChange}
              /> 
              <Input className="w-[4rem]" size="small" value={quality} onChange={(e) => setQualityValue(e.target.value)} inputProps={{type:'number',placeholder:"MaxSizeMB", min:0,max:100}}/>
            </Box>
            <label>Keep Resolution: <Checkbox aria-label="Keep Resolution" name="Quality" value={keepResolution} checked={keepResolution} onChange={(e) => setKeepResolution(e.target.checked)}/></label>
            
        </Box>
      </Box>
      

      { isUploading ? 
            <Loader label={"Uploading File..."}/>
       : null }

      { isCompressing ? 
                  <Loader label={"Compressing File..."}/>
            : null }

    </div>
  )
}
