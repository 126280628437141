import { useEffect, useState } from 'react';
import ImagePreview from '../components/Preview/ImagePreview';
import DragInput from '../components/DragAndDrop/DragInput';
import JSZip from 'jszip';
import Button from '../components/Button/Button';


export default function Home() {

  const [files, setFiles] = useState<any>([]);

  const setInputFiles = (files : any) => {
    setFiles(files);
    console.log(files);
  }

  function isEmpty() {
    return files.length === 0;
  }

  async function downloadFile(file : File) {

    var FileSaver = require('file-saver');
    FileSaver.saveAs(file);

    const zip = new  JSZip();

    zip.file(file.name, file);
  }

  async function downloadAll() {
    
    var FileSaver = require('file-saver');
    const zip = new  JSZip();

    files.forEach((file : File) => {
      zip.file(`compressed_${file.name}`, file);
    });

    zip.file('readme.txt', 'Compressed via https://image-compressor.codecrafted.in');

    const zipFile = await zip.generateAsync({type: 'blob'});

    FileSaver.saveAs(zipFile);
  }

  useEffect(() => {
    console.log(files.length)
  }, [files]);

  return (
    <>
    <main className="Home min-h-screen flex flex-col items-center justify-center py-[10rem] px-[5vw] box-border gap-[1rem] text-[#F6F1F1]">
     <h1 className='text-3xl text-white'>Image Compressor</h1>
     <p className='text-white mt-1'>Best tool Compressor to compress all types of images for free.</p>
        <DragInput setInputFiles={setInputFiles}/>
          

        {!isEmpty() ? 
            <article className="flex flex-col w-1/2 min-w-[70%] md:min-h-[20rem] lg:min-h-[20rem] max-[640px]:w-full max-[640px]:min-h-[20rem] max-md:w-3/4 max-lg:w-3/4 h-min-64 justify-center items-center bg-[#8fa8a5] rounded-xl m-5 p-2">
              <label className="font-light text-[1.5vw] max-[640px]:text-[1rem] text-black">Compressed Images:</label>
              <aside className="w-full flex flex-row flex-wrap p-5 justify-center items-end">
              {
                files.map((file : any, i : number) => {
                                            return <>
                                                <ImagePreview onClick={() => downloadFile(file)} imageFile={file} mode={"output"} key={i}/>
                                            </>
                                          })
                                        
              }
              
              </aside>
              <Button disabled={false} onClick={downloadAll} label={"Download All"} wrapperClass={"bg-[#e54630]"}/>
          </article> : null
      
        }
        {/* <article className="flex flex-col w-1/2 justify-center items-center bg-gray-400 rounded-xl m-5 p-2">
          { !isEmptyCompressed() && !isCompressing ? <label className="font-light text-white">Compressed Images:</label> : null }
            <aside className="w-full flex flex-row flex-wrap p-5 justify-center items-center">
            {
              !isCompressing ? compressedFiles.map((file : any, i : number) => {
                                          return <>
                                              <aside className='flex flex-col justify-center items-center border-2 rounded-xl'>
                                                  <ImagePreview imageFile={file} key={i}/>
                                                  <button className='cursor-pointer' onClick={ () => downloadFile(file)}>Download</button>
                                              </aside>
                                          </>
                                        })
                                      : null
            }
            
            </aside>
            { !isEmptyCompressed() && !isCompressing ? <button className="font-light text-white bg-[green] rounded-xl p-2" onClick={downloadAll}>Download All</button> : null }
        </article> */}
    </main>
    </>
  )
}
