import { useEffect, useState } from "react";
import remove from '../../assets/img/delete.png';
import download from '../../assets/img/download.png';

export default function ImagePreview({imageFile, onClick, mode} : {imageFile : any, onClick:any, mode:any}) {
  
    const [imageURL, setImageURL] = useState<any>();

    useEffect(() => {
        if(!imageFile) return;
        const url = URL.createObjectURL(imageFile);
        setImageURL(url);
        return () => { url && URL.revokeObjectURL(url)};
    }, [imageFile]);
  
    return imageFile ? (
          <article className="rounded-[5px] flex flex-col p-1 m-1 items-center text-white drop-shadow-[2px_4px_4px_gray] border-[.2px] border-[#a6a4a4]">
                <div className="flex flex-col w-[5vw] min-w-[5rem] text-white justify-end items-center">
                    <img className="w-full h-auto duration-200" src={imageURL} alt="" />
                    <p className="">{`${(imageFile.size / 1024 / 1024).toFixed(2)} MB`}</p>
                  </div>
                <label className="font-light cursor-pointer hover:scale-110 duration-500" onClick={onClick}><img className="w-8" src={mode === "input" ? remove : download} alt="Icon"/></label>
            </article>
  ) : null
}
